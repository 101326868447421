const zh_CN = {
    "cluster": "集群",
    'reset': '重置',
    'refresh': '刷新',
    'batch-delete': '批量删除',
    'import-cluster': '接入集群',
    'edit-cluster': '编辑',
    'edit': '编辑',
    'delete': '删除',
    'total-items': '总计 {total} 条',
    'index': '序号',
    'name': '名称',
    'servers': '集群地址',
    'topic': '主题',
    'topic-detail': '主题详情',
    'broker': 'Broker',
    'brokers': 'Brokers',
    'consumer-group': '消费组',
    'consumer-groups': '消费组列表',
    'consumer-group-detail': '消费组详情',
    'created': '创建时间',
    'operate': '操作',
    'delete-confirm': '您确认要删除此记录吗？',
    'okText': '确定',
    'cancelText': '取消',
    'save': '保存',

    'partitions': '分区',
    'replicas': '副本',
    'log-size': '数据大小',
    'average-log-size': '平均数据大小',
    'numPartitions': '分区数量',
    'replicationFactor': '副本数量',
    'topic-alter': '分区扩容',
    'create-topic': '新增主题',

    'topic-management': '主题管理',
    'broker-management': 'Broker 管理',
    'consumer-group-management': '消费组管理',

    'topic-count': '主题数量',
    'topic-list': '主题列表',
    'topic-config': '主题配置',
    'subscribed-topic': '已订阅的主题',

    'consume-detail': '消费详情',
    'reset-warning': '警告',
    'reset-warning-description': '重置前需关闭消费者客户端。',
    'reset-partition': '重置位置',
    'latest': '最新',
    'earliest': '最早',
    'customization': '自定义',
    'consume-message': '拉取数据',
    'consume-message-live': '实时拉取数据',
    'produce-message': '导入数据',
    'pull': '拉取',
    'stop': '停止',
    'newest': '最新',
    'delay-message': '延迟消息',

    'security-protocol': '安全协议',
    'sasl-mechanism': '协议机制',
    'username': '账户',
    'password': '密码',
    'change-password': '修改密码',
    'logout': '注销登录',
    'delay-message-information1': '请注意：开启后会自动创建 19 个内置 Topic 。',
    'delay-message-information2': '基于kafka的延迟消息服务，支持18个级别，1s 5s 10s 30s 1m 2m 3m 4m 5m 6m 7m 8m 9m 10m 20m 30m 1h 2h。',
    'delay-message-information3': '发送消息到主题: delay-message，level: 0-17 。',
}

export default zh_CN;